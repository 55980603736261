import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import ReactGA from 'react-ga4';
import "./DateListPage.css";

const DateListPage = () => {
  const [questionsWithTitles, setQuestionsWithTitles] = useState([]);
  const [filteredTitles, setFilteredTitles] = useState([]); 
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); 
  const [loading, setLoading] = useState(true);
  const [titlesPerPage] = useState(12);
  const navigate = useNavigate();
  ReactGA.initialize('G-GH0Q65HHQ4'); 

  const generateSlug = (title) => {
    const turkishMap = {
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ç: "c",
      Ç: "C",
      ü: "u",
      Ü: "U",
      ö: "o",
      Ö: "O",
      ğ: "g",
      Ğ: "G",
    };

    title = title.replace(/[şŞıİçÇüÜöÖğĞ]/g, (match) => turkishMap[match]);

    return title
      .toString() 
      .toLowerCase() 
      .trim()
      .replace(/[\?\!\.\,]/g, "") 
      .replace(/\s+/g, "-") 
      .replace(/[^\w\-]+/g, "") 
      .replace(/\-\-+/g, "-") 
      .replace(/^-+/, "") 
      .replace(/-+$/, ""); 
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://kuiz.fun/api/getQuestionByTitle2.php",
          { lim: 1000, offset: 0 }
        );

        if (response.data && Array.isArray(response.data)) {
          setQuestionsWithTitles(response.data);
          setFilteredTitles(response.data); 
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const filterTitles = (query) => {
    if (!query) {
      setFilteredTitles(questionsWithTitles);
    } else {
      const filtered = questionsWithTitles.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredTitles(filtered);
    }

    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getPaginatedTitles = () => {
    const startIndex = (currentPage - 1) * titlesPerPage;
    const endIndex = startIndex + titlesPerPage;
    return filteredTitles.slice(startIndex, endIndex);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleTitleClick = (title) => {
    ReactGA.event({
      category: 'Quiz',
      action: 'Click',
      label: title,
    });
    const slug = generateSlug(title);
    navigate(`/${slug}`);
  };

  useEffect(() => {
    filterTitles(searchQuery);
  }, [searchQuery]);

  return (
    <>
      <Navbar />
      <div className="title-list-container">
        <div className="search-container">
          <input
            type="text"
            placeholder="Kuiz ara"
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
          />
        </div>

        <div className="title-list">
          {loading ? (
            <p>Sayfa yükleniyor...</p>
          ) : getPaginatedTitles().length > 0 ? (
            getPaginatedTitles().map((item, index) => (
              <button
                key={index}
                className="title-button"
                onClick={() => handleTitleClick(item.title)}
              >
                {item.title}
              </button>
            ))
          ) : (
            <p>Şimdilik bu kadar.</p>
          )}
        </div>

        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Önceki sayfa
          </button>
          <span>{currentPage}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * titlesPerPage >= filteredTitles.length}
          >
            Sonraki sayfa
          </button>
        </div>
      </div>
    </>
  );
};

export default DateListPage;
