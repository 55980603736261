import React, { useState } from "react";
import "./Panel3.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { format } from "date-fns";

function Panel3() {
  const [formData, setFormData] = useState({
    question: "",
    img_url: "",
    correct_answer: "",
    hint1: "",
    hint2: "",
    hint3: "",
    hint4: "",
    hint5: "",
    date: null,
    autocompleteOptions: ["footballClubs"],
    autocomplete_list: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    console.log(date);
    date = format(date, "yyyy-MM-dd");
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post(
        "https://kuiz.fun/api/whoamiQuestionEntry.php",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Veriler işlendi!", response.data);
      setFormData({
        question: "",
        correct_img: "",
        correct_answer: "",
        wrong_img: "",
        wrong_answer: "",
        date: null,
        title: "",
      });
    } catch (error) {
      console.log("Veriler işlenirken bir sorun oluştu!", error);
    }
  };

  return (
    <div className="panel3-container">
      <div className="question3-part">
        <h2>Ben Kimim Soru giriş ekranı</h2>
        <form onSubmit={handleSubmit} className="db3-form">
          <label>
            <span>Görsel:</span>
            <input
              type="text"
              name="img_url"
              value={formData.img_url}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>Doğru cevap:</span>
            <input
              type="text"
              name="correct_answer"
              value={formData.correct_answer}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>1. ipucu:</span>
            <input
              type="text"
              name="hint1"
              value={formData.hint1}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>2. ipucu:</span>
            <input
              type="text"
              name="hint2"
              value={formData.hint2}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>3. ipucu:</span>
            <input
              type="text"
              name="hint3"
              value={formData.hint3}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>4. ipucu:</span>
            <input
              type="text"
              name="hint4"
              value={formData.hint4}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>5. ipucu:</span>
            <input
              type="text"
              name="hint5"
              value={formData.hint5}
              onChange={handleChange}
            />
          </label>
          <label>
            <span>Tarih:</span>
            <DatePicker
              selected={formData.date}
              onChange={handleDateChange}
              minDate={new Date()}
              dateFormat="yyyy-MM-dd"
            />
          </label>
          <label>
            <span>Autocomplete:</span>
            <select
              name="autocomplete_list"
              value={formData.autocomplete_list}
              onChange={handleChange}
            >
              <option value="" disabled hidden>
                Liste seçin
              </option>
              <option value="footballPlayers">footballPlayers</option>
            </select>
          </label>
          <button type="submit">Gönder</button>
        </form>
      </div>
    </div>
  );
}

export default Panel3;