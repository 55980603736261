import footballClubs from "./footballClubs.json";
import footballPlayers from "./footballPlayers.json";
import nationalTeams from "./nationalTeams.json";

export function handleFile(path) {
  if (path === "nationalTeams") {
    return nationalTeams;
  } else if (path === "footballClubs") {
    return footballClubs;
  } else if (path === "footballPlayers") {
    return footballPlayers;
  } 
}

export default handleFile;
