import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import axios from "axios";
import Modal from "../Modal/Modal";
import Autosuggest from "react-autosuggest";
import handleFile from "../../data/fileHandler";
import "./WhoAmI.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaCalendarAlt } from "react-icons/fa";

const BlurredImage = ({ imageUrl, isBlurred }) => {
  useEffect(() => {
    const imgElement = document.querySelector(".blurred-image");
    const adjustImage = () => {
      if (imgElement) {
        const naturalWidth = imgElement.naturalWidth;
        const naturalHeight = imgElement.naturalHeight;
        const aspectRatio = naturalWidth / naturalHeight;

        if (aspectRatio > 1) {
          imgElement.style.width = "100%";
          imgElement.style.height = "auto";
        } else {
          imgElement.style.height = "100%";
          imgElement.style.width = "auto";
        }
      }
    };

    imgElement?.addEventListener("load", adjustImage);

    return () => {
      imgElement?.removeEventListener("load", adjustImage);
    };
  }, [imageUrl]);

  return (
    <div className="blurred-image-container">
      <img
        src={imageUrl}
        alt="Blurred version"
        className={`blurred-image ${isBlurred ? "blur" : ""}`}
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  );
};

const WhoAmI = () => {
  const [questions, setQuestions] = useState([]);
  const [autocomp, setAuto] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState(null);
  const [value, setValue] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [hintIndex, setHintIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [lives, setLives] = useState(5);
  const [streakCounter, setStreakCounter] = useState("0");
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [solved, setSolved] = useState(true);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [originalDate, setOriginalDate] = useState(new Date());
  const [isImageBlurred, setIsImageBlurred] = useState(true);
  const category = "Ben Kimim?";

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = `${date.getDate()}`.padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchQuestions = async (date) => {
    localStorage.setItem("isChangeable", "true");

    const formattedDate = formatDate(date);
    const postData = { category, date: formattedDate };
    try {
      const response = await axios.post(
        "https://kuiz.fun/api/whoAmIQuestion.php",
        postData,
        { headers: { "Content-Type": "application/json" } }
      );
      const data = response.data;
      if (data && !data.error) {
        setQuestions(data);
        setAuto(data[0]?.autocomplete_list);
      } else {
        console.error("Error fetching questions:", data.error);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    fetchQuestions(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (correctAnswersCount === 1 || lives === 0) {
      const element2 = document.getElementById("inputs");
      element2.style.display = "none";
      setTimeout(() => {
        setIsImageBlurred(false);
        setTimeout(() => {
          setShowModal(true);
        }, 700);
      });
    }
  }, [correctAnswersCount, lives]);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const resetGame = () => {
    const element2 = document.getElementById("inputs");
    element2.style.display = "flex";
    setLives(5);
    setHintIndex(0);
    setCorrectAnswersCount(0);
    setSelectedAnswers([]);
    setSolved(true);
    setWrongAnswers([]);
    setValue("");
    setIsCorrect(null);
    setIsImageBlurred(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setOriginalDate(date);
    setShowCalendar(false);
    resetGame();
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (correctAnswersCount === 0 && lives > 0) {
      setIsImageBlurred(true);
    }
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    var myArray = handleFile(autocomp);
    myArray = myArray["data"].split(",");

    const filteredSuggestions = myArray.filter(
      (music) => !selectedAnswers.includes(music.toLowerCase())
    );

    return inputLength === 0
      ? []
      : filteredSuggestions.filter((music) =>
          music.toLowerCase().includes(inputValue)
        );
  };

  const inputProps = {
    placeholder: "Cevabınızı giriniz",
    value,
    onChange: (_, { newValue }) => setValue(newValue),
  };

  const checkAnswer = (selectedAnswer) => {
    const currentQuestion = questions[currentQuestionIndex];
    setUserAnswer(selectedAnswer);

    const isAnswerCorrect =
      selectedAnswer.toLowerCase() ===
      currentQuestion.correct_answer.toLowerCase();
    setIsCorrect(isAnswerCorrect);

    if (isAnswerCorrect) {
      let currentStreak = parseInt(localStorage.getItem("whoAmIStreak")) || 0;
      currentStreak += 1;
      localStorage.setItem("whoAmIStreak", currentStreak.toString());
      setStreakCounter(currentStreak);

      const element2 = document.getElementById("inputs");
      element2.style.display = "none";
      setCorrectAnswersCount((prevCount) => prevCount + 1);
    } else {
      setLives((prevLives) => prevLives - 1);
      if (hintIndex < 4) {
        setHintIndex((prevHintIndex) => prevHintIndex + 1);
      }

      if (lives === 0) {
        setIsImageBlurred(false);
        setShowModal(true);
        const element2 = document.getElementById("inputs");
        element2.style.display = "none";
      }

      setWrongAnswers((prevWrongAnswers) => [
        ...prevWrongAnswers,
        selectedAnswer,
      ]);
    }

    setSelectedAnswers((prevSelectedAnswers) => [
      ...prevSelectedAnswers,
      selectedAnswer.toLowerCase(),
    ]);
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <>
      <Navbar />
      <div className="game-container">
        <div className="icons-container">
          <div className="whoami-calendar-container">
            <div
              className={`whoami-calendar-button ${
                showCalendar ? "" : "hide-calendar-button"
              }`}
              onClick={toggleCalendar}
            >
              <FaCalendarAlt size={30} />
            </div>
            <div className="heart-container">
              {Array.from({ length: lives }, (_, i) => (
                <span
                  key={i}
                  className={i === lives - 1 ? "heart-animation-alert" : ""}
                >
                  ❤️
                </span>
              ))}
            </div>
          </div>

          {showCalendar && (
            <div className="whoami-calendar-modal">
              <Calendar
                onChange={handleDateChange}
                value={selectedDate}
                minDate={new Date(2024, 11, 15)}
                maxDate={new Date()}
              />
            </div>
          )}
        </div>

        {currentQuestion ? (
          <>
            {lives === 0 && (
              <h2 className="correct-answer">
                <strong>Doğru Cevap: </strong>
                {currentQuestion.correct_answer}
              </h2>
            )}
            <div className="picture-container">
              <BlurredImage
                imageUrl={currentQuestion.img_url}
                isBlurred={isImageBlurred}
              />
              <div className="hint-container">
                {hintIndex >= 1 && (
                  <p>
                    <strong>🌍 Milliyet: </strong> {currentQuestion.hint1}
                  </p>
                )}
                {hintIndex >= 2 && (
                  <p>
                    <strong>🏃‍♂️ Mevki:</strong> {currentQuestion.hint2}
                  </p>
                )}
                {hintIndex >= 3 && (
                  <p>
                    <strong>🏆 Oynadığı Lig:</strong> {currentQuestion.hint3}
                  </p>
                )}
                {hintIndex >= 4 && (
                  <p>
                    <strong>🏟️ Oynadığı Takım:</strong> {currentQuestion.hint4}
                  </p>
                )}
                {hintIndex >= 5 && (
                  <p>
                    <strong>⏪ Önceki Takım:</strong> {currentQuestion.hint5}
                  </p>
                )}
              </div>
            </div>
            <div className="quiz-input" id="inputs">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={({ value }) =>
                  setSuggestions(getSuggestions(value))
                }
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={(suggestion, { isHighlighted }) => (
                  <div
                    className={`autosuggest-suggestion ${
                      isHighlighted ? "focused" : ""
                    }`}
                  >
                    {suggestion}
                  </div>
                )}
                onSuggestionSelected={(event, { suggestion }) => {
                  checkAnswer(suggestion);
                  setValue("");
                }}
                inputProps={inputProps}
                renderSuggestionsContainer={({ containerProps, children }) => (
                  <div {...containerProps} className="autosuggest-container">
                    <div className="autosuggest-suggestions-container">
                      {children}
                    </div>
                  </div>
                )}
              />
            </div>
            {wrongAnswers.length > 0 && (
              <div className="wrong-answers-container">
                <h3>Önceki tahminler:</h3>
                <ul>
                  {wrongAnswers.map((answer, index) => (
                    <li key={index}>{answer}</li>
                  ))}
                </ul>
              </div>
            )}
          </>
        ) : (
          <p>Sorular yükleniyor...</p>
        )}
      </div>
      {showModal && (
        <Modal
          correctAnswersCount={correctAnswersCount}
          streakCounter={streakCounter}
          totalQuestions={1}
          solved={solved}
          showConfetti={correctAnswersCount === 1}
          category={category}
          onClose={handleModalClose}
          progress={correctAnswersCount === 1 ? 100 : 0}
        />
      )}
    </>
  );
};

export default WhoAmI;
